
import React from 'react';
import { Alert,Button, Container,Form, FormGroup, Label, Input,Card,CardBody} from 'reactstrap';
import Switch from "react-bootstrap-switch";
import urlHead from "./backapi.js"


class AddEditForm extends React.Component {
  currDate (){
    var today = new Date();

    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    return date
  }
      state = {
      eff_id: 0,
      author: '',
      record_date: this.currDate(),
      tasks_done: '',
      note: '',
      pts: '',
      critBased: false,
      selectMulti:'',
      loadedCrit:[],
      alert_trig:false,
      sucessLose:false,
      alertMsg:''


    }
    onChange = e => {
    this.setState({[e.target.name]: e.target.value})
    //console.log(this.state.selectMulti)
  }

  async getCrit(){
  //  e.preventDefault()
    await fetch(urlHead+ "api/viewcrit/",{
      method:"GET",
      headers:
      {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization':'Token '+localStorage.authToken
      }
    })
      .then(response=>response.json())
      .then(responseJSON=>{
        if ("detail" in responseJSON){
          this.setState({alertMsg:responseJSON["detail"]})
          this.setState({alert_trig:true})
          this.setState({sucessLose:false})
          return //this can put up some error alert
        }
        else{
          this.setState({loadedCrit:responseJSON})
        }
      })
      .catch(er=>{
        console.log("error in loading criteria:",er)
        this.setState({alertMsg:"Unknown errors Occurs on the Back-End"})
        this.setState({alert_trig:true})
        this.setState({sucessLose:false})
      })
  }


  submitFormAdd = e =>{
    e.preventDefault()
    fetch(urlHead+"api/vieweffo/",{
      method:"POST",
      headers:
      {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization':'Token '+localStorage.authToken
      },
      body:JSON.stringify(
      {
        record_date:this.state.record_date,
        tasks_done: this.state.tasks_done,
        note:this.state.note,
        pts:this.state.pts

      })
    })
    .then(response=>response.json())
    .then(responseJSON => {
      if (responseJSON["status"] === "set"){
        this.setState({alertMsg:"successfully added"})
        this.setState({alert_trig:true})
        this.setState({sucessLose:true})


        const item = {
          author:responseJSON["author"],
          eff_id:responseJSON["effo_id"],
          record_date:this.state.record_date,
          tasks_done: this.state.tasks_done,
          note:this.state.note,
          pts:this.state.pts

        }
        this.props.addItemtoState(item)

      }
      else{
        this.setState({alertMsg:"Errors Occured when attempting to add query"})
        this.setState({alert_trig:true})
        this.setState({sucessLose:false})
        console.log('FAILURE, ERROR ON ADDING')}

    })
    .catch(err=>{
      console.log(err)
      this.setState({alertMsg:"Unknown errors Occurs on the Back-End"})
      this.setState({alert_trig:true})
      this.setState({sucessLose:false})

    })
  }

  submitFormEdit = e => {
    e.preventDefault()
    fetch(urlHead+"api/vieweffo/"+(this.state.eff_id).toString()+"/",{
      method: "PUT",
      headers:{
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization':'Token '+localStorage.authToken
      },
      body:JSON.stringify(
      {
        record_date:this.state.record_date,
        tasks_done: this.state.tasks_done,
        note:this.state.note,
        pts:this.state.pts
      })
    })
    .then(response=>response.json())
    .then(responseJSON=>{
      if (responseJSON["status"]==="set"){
        const item = {
          author:responseJSON["author"],
          eff_id:this.state.eff_id,
          record_date:this.state.record_date,
          tasks_done: this.state.tasks_done,
          note:this.state.note,
          pts:this.state.pts
        }
        this.props.updateState(item)
        this.props.toggle()
        // this.setState({alertMsg:"successfully edited"})
        // this.setState({alert_trig:true})
        // this.setState({sucessLose:true})

      }
      else{
        console.log("FAILURE, ERROR ON EDITING")
        console.log("~~~~~~~~",responseJSON,"~~~~~~~~")
        var er_output = ''
        for (let x in responseJSON){
          er_output = er_output+responseJSON[x]
        }
        this.setState({alertMsg:"Error: "+er_output})
        this.setState({alert_trig:true})
        this.setState({sucessLose:false})

      }
    })
    .catch(err=>{
      this.setState({alertMsg:"Errors occured when edited, please make sure you are editing your own entry"})
      this.setState({alert_trig:true})
      this.setState({sucessLose:false})
      console.log(err)})
  }
  handleSwitch(elem,state){
    this.setState({ critBased:state })

  }

  handleCritChange = (event) => {
    const selectedHashTags = Array.from(event.target.options)
      .reduce((hashtags, hashtag) => {
        if (hashtag.selected) {
          hashtags.push(hashtag.value);
        }

        return hashtags;
      }, []);

    this.setState(() => ({ selectMulti: selectedHashTags }));

    console.log("in Handle Auto Tasks Done")
    const allCrit = this.state.loadedCrit
    const selected = selectedHashTags
    var output = ''
    var currTotal = 0
    for (var idx = 0;idx < selected.length;idx++){
      var currLoad
      var currID = Number(selected[idx])
      for (var n =0;n<allCrit.length;n++)
    {
        if (allCrit[n].cri_id == currID){
          currLoad = allCrit[n]
          break
        }
    }
      if (currLoad)
    {
      output = output + currLoad.cri_att+ ', '
      currTotal = currTotal+currLoad.pts
    }
  }
    if (output != ''){

      this.setState({tasks_done:output})

      this.setState({pts:currTotal})

    }

  }
  handleAutoTd =(event) => {
    // console.log("in Handle Auto Tasks Done")
    // const allCrit = this.state.loadedCrit
    // const selected = this.state.selectMulti
    // var output = ''
    // for (var idx = 0;idx < selected.length;idx++){
    //   var currLoad
    //   var currID = Number(selected[idx])
    //   for (var n =0;n<allCrit.length;n++){
    //     if (allCrit[n].cri_id == currID){
    //       currLoad = allCrit[n]
    //       break
    //     }
    //   }
    // if (currLoad)
    // {output = output + currLoad.cri_att}
    // }
    // if (output != ''){
    //   this.setState({tasks_done:output})
    // }
  //
   }
   ondm=()=>
   {this.setState({alert_trig:false})}
  componentDidMount(){
    if (this.props.item){
      const {eff_id,author,record_date,tasks_done,note,pts}= this.props.item
      this.setState({eff_id,author,record_date,tasks_done, note,pts})

    }
    this.getCrit()


  }

  render(){
    const opts = this.state.loadedCrit.map(eachKey=>{
      return (
        <option value = {eachKey.cri_id}>{eachKey.cri_att}: {eachKey.pts}</option>
      )
    })


    return (
    <Container>
    <Card>
    {this.state.alert_trig &&
      <Alert

      color={this.state.sucessLose?"success":"danger"}
      toggle = {this.ondm}
      >
      <strong>{this.state.alertMsg}</strong>
      </Alert>}
    <CardBody>
       <form onSubmit={this.props.item ? this.submitFormEdit : this.submitFormAdd}>


        <FormGroup>
          <Label for = "record_date"> Record Date </Label>
          <Input
          type="text"
          placeholder="YYYY-MM-DD"
          name="record_date" id="record_date"
          onChange={this.onChange}
          value={this.state.record_date === null ? '' : this.state.record_date} />
        </FormGroup>

        <Switch offColor="" offText="" onColor="" onText="auto"
        defaultValue = {false}
        value = {this.state.critBased}
        name = "critBased"
        onChange={(el, state) => this.handleSwitch(el, state)}
         />
            <br />




        {this.state.critBased &&

          <FormGroup>
                <Label for="exampleSelectMulti1">Example multiple select</Label>
                <Input
                  type="select"
                  name="selectMulti"
                  id="exampleSelectMulti1"
                  value = {this.state.selectMulti}
                  onChange = {this.handleCritChange}
                  multiple
                >
                {opts}
                </Input>
              </FormGroup>

        }

        <FormGroup>
          <Label for = "tasks_done"> Tasks Done </Label>
          <Input
          type="text"
          name="tasks_done" id="tasks_done"
          onChange={ this.state.critBased ===true? this.handleAutoTd:this.onChange}

          value={this.state.tasks_done === null ? '' : this.state.tasks_done} />
        </FormGroup>


        <FormGroup>
          <Label for = "note"> Note/Release </Label>
          <Input
          type="text"
          name="note" id="note"
          onChange={this.onChange}
          value={this.state.note === null ? '' : this.state.note} />
        </FormGroup>

        <FormGroup>
          <Label for = "pts"> Points </Label>
          <Input
          type="number"
          name="pts" id="pts"
          onChange={this.state.critBased ===true? this.handleAutoTd :this.onChange}
          value={this.state.pts === null ? '' : this.state.pts} />
        </FormGroup>

        <Button>Submit</Button>

        </form>

    </CardBody>
    </Card>
    </Container>







    )

  }


}

export default AddEditForm
