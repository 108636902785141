import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import {Redirect} from "react-router-dom"
import urlHead from "./backapi.js"
// core components
import ProfileNavbar from "./profileBar.js";
// import Footer from "components/Footer/Footer.js";
import auth from "./auth.js"
import UserAuth from "./userAuth.js"
class ProfilePage extends React.Component {
  constructor(props){
  super(props)
  this.state = {
    squares1to6: "",
    squares7and8: "",

    resUsername:"",
    resAlert : false,
    reSuccess: false,

    userName:"",
    firstName:"",
    lastName:"",
    email:"",
    newpassWord:"",
    currpassWord:"",
    flag:true

  }
  this.handleFormChange=this.handleFormChange.bind(this)
  };

  handleFormChange(event){
    this.setState({
      [event.target.name]:event.target.value
    })
  }

  async componentDidMount() {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", this.followCursor);
    var stuff = await UserAuth.isAuthenticated()
    this.setState({flag:stuff})
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
    document.documentElement.removeEventListener(
      "mousemove",
      this.followCursor
    );
  }
  followCursor = event => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    this.setState({
      squares1to6:
        "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)",
      squares7and8:
        "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    });
  };
  render() {
    if (this.state.resAlert){
      if (this.state.reSuccess)
      {
          alert = <Alert color = "success"> account details updated successfully, {this.state.resUsername} </Alert>
      }
      else
      {
          alert = <Alert color = "danger"> account details fail to update, check the error:{this.state.resUsername} </Alert>
      }
    }
    else{
      alert = <Alert color = "info"> Welcome {localStorage.username}, please enter the info you want to update</Alert>
    }

    var status = false
    if (this.state.flag == false ){
       status = false
    }
    else{
      status = true
    }
    const quote = status

    return (
      <>
      {  !quote &&
        <Redirect push to = "/login" />
      }
        <ProfileNavbar />
        <div className="wrapper">
          <div className="page-header">
            <div className="page-header-image" />
            <div className="content">
              <Container>
                <Row>
                  <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                    <div
                      className="square square-7"
                      id="square7"
                      style={{ transform: this.state.squares7and8 }}
                    />
                    <div
                      className="square square-8"
                      id="square8"
                      style={{ transform: this.state.squares7and8 }}
                    />
                    <Card className="card-register">
                      <CardHeader>
                        <CardImg
                          alt="..."
                          src={require("assets/img/square-purple-1.png")}
                        />
                        <CardTitle tag="h4">Profile</CardTitle>
                      </CardHeader>
                      <CardBody>
                        {alert}
                        <Form className="form">




                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.firstNameFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-badge" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.firstName}
                              name = "firstName"
                              placeholder="First Name"
                              type="text"
                              onChange={this.handleFormChange}
                              onFocus={e =>
                                this.setState({ firstNameFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ firstNameFocus: false })
                              }
                            />
                          </InputGroup>


                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.lastNameFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-badge" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.lastName}
                              name = "lastName"
                              onChange={this.handleFormChange}
                              placeholder="Last Name"
                              type="text"
                              onFocus={e =>
                                this.setState({ lastNameFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ lastNameFocus: false })
                              }
                            />
                          </InputGroup>


                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.emailFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.email}
                              name = "email"
                              onChange={this.handleFormChange}
                              placeholder="Email"
                              type="text"
                              onFocus={e => this.setState({ emailFocus: true })}
                              onBlur={e => this.setState({ emailFocus: false })}
                            />
                          </InputGroup>

                          <Button
                          onClick = {
                            async() =>
                        {

                        if (localStorage.authToken){
                          var targetUrl = urlHead+"users/me/"
                          await fetch(targetUrl,
                          {
                            method:'PUT',
                            headers:
                            {
                              'Content-Type':'application/json',
                              'Accept': 'application/json',
                              'Authorization':'Token '+localStorage.authToken
                            },
                            body: JSON.stringify({

                              first_name:this.state.firstName,
                              last_name:this.state.lastName,
                              email:this.state.email,

                            })
                          })
                          .then(response=>response.json())
                          .then((responseJSON)=>{
                            if ("id" in responseJSON)
                            { this.setState({reSuccess:true})
                              this.setState({resAlert:true})
                              this.setState({resUsername:responseJSON["username"]})
                              console.log(this.state.resAlert," ",this.state.resUsername)

                            }

                            else{
                              if ("detail" in responseJSON)
                              {
                                if (responseJSON["detail"]=="Invalid token.")
                                {
                                  auth.login(()=>{
                                  this.props.history.push("/login");});
                                }
                              }
                              this.setState({reSuccess:false})
                              this.setState({resAlert:true})
                              var displayMsg = ""
                              for (let item in responseJSON){
                                displayMsg = displayMsg+responseJSON[item]
                              }
                              this.setState({resUsername:displayMsg})
                              console.log(this.state.resAlert," ",this.state.resUsername)
                            }
                          })
                        }
                        else{
                          auth.login(()=>{
                            this.props.history.push("/app");});
                        }

                        }

                        }
                          className="btn-round"
                          color="primary"
                          size="sm">
                            Update

                          </Button>




                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.passwordFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-lock-circle" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.currpassWord}
                              name = "currpassWord"
                              onChange={this.handleFormChange}
                              placeholder="Current Password"
                              type="password"
                              onFocus={e =>
                                this.setState({ passwordFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ passwordFocus: false })
                              }
                            />
                          </InputGroup>

                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.newpasswordFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-lock-circle" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.newpassWord}
                              name = "newpassWord"
                              onChange={this.handleFormChange}
                              placeholder="New Password"
                              type="password"
                              onFocus={e =>
                                this.setState({ newpasswordFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ newpasswordFocus: false })
                              }
                            />
                          </InputGroup>

                          <Button
                          onClick = {
                            async () =>
                            {
                      if (localStorage.authToken)
                        {
                          var targetUrl = urlHead+"users/set_password/"
                          await fetch(targetUrl,
                          {
                            method:'POST',
                            headers:
                            {
                              'Content-Type':'application/json',
                              'Accept': '*/*',
                              'Authorization':'Token '+localStorage.authToken
                            },
                            body: JSON.stringify({

                              current_password:this.state.currpassWord,
                              new_password:this.state.newpassWord

                            })
                          })
                          .then(async (response)=>
                        {
                            if (response.ok)
                              {
                                this.setState({reSuccess:true})
                                this.setState({resAlert:true})
                                this.setState({resUsername:localStorage.username})
                                console.log(this.state.resAlert," ",this.state.resUsername)
                              }
                            else{

                              this.setState({reSuccess:false})
                              this.setState({resAlert:true})

                              let responseJSON = await response.json()
                              if (response.status!=401)
                              { var displayMsg = ""
                                for (let item in responseJSON){
                                  displayMsg = displayMsg+responseJSON[item]
                                  //console.log(displayMsg,"\n woofffff")
                                }
                                this.setState({resUsername:displayMsg})

                                console.log(this.state.resAlert," ",this.state.resUsername)
                              }
                              else
                              {

                                  auth.login(()=>{
                                  this.props.history.push("/login");});

                              }
                              console.log(responseJSON,"<-----this is the error response")

                            }

                            }
                        )
                      }
                      else{
                        auth.login(()=>{
                          this.props.history.push("/app");});
                      }



                        }

                        }
                          className="btn-round"
                          color="warning"
                          size="sm">
                            Submit Password

                          </Button>



                        </Form>
                      </CardBody>
                      <CardFooter>

                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
                <div className="register-bg" />
                <div
                  className="square square-1"
                  id="square1"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-2"
                  id="square2"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-3"
                  id="square3"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-4"
                  id="square4"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-5"
                  id="square5"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-6"
                  id="square6"
                  style={{ transform: this.state.squares1to6 }}
                />
              </Container>
            </div>
          </div>

        </div>
      </>
    );
  }
}

export default ProfilePage;
