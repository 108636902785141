import React from 'react'
import { Table,
  Button,
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert,
  Alert
 } from 'reactstrap';
import ModalForm from "./Modal.js"

import urlHead from "./backapi.js"

class DataTable extends React.Component {
  state = {
    del_Alert : false,
    del_suc:false,
    del_msg:''
  }
  deleteItem = id =>
  {
    let confirmDelete = window.confirm('Delete item forever?')
    if (confirmDelete){
      fetch (urlHead+"api/vieweffo/"+id.toString()+"/",
      { method:"DELETE",
        headers:{
          'Content-Type':'application/json',
          'Accept': '*/*',
          'Authorization':'Token '+localStorage.authToken
        }
      })
      .then(response=>response.json())
      .then(responseJSON=>{
        if (responseJSON["status"] === "set"){
          this.props.deleteItemfromState(id)
          this.setState({del_Alert:true})
          this.setState({del_msg:"entry was successfully deleted"})
          this.setState({del_suc:true})

        }
        else{
          this.setState({del_Alert:true})
          var msg = ''
          for (let x in responseJSON){
            msg = msg + responseJSON[x]+'; '
          }
          this.setState({del_msg:msg})
          this.setState({del_suc:false})
        }
        console.log(responseJSON,"delete error msg")
      })
      .catch(err=>{
        console.log(err)
        this.setState({del_Alert:true})
        this.setState({del_msg:"Backend Errors Occur when deleted"})
        this.setState({del_suc:false})
      })
    }
  }
  onDismiss=()=>
  {this.setState({del_Alert:false})}


  render(){

    var itemArr = this.props.items

    const items = itemArr.sort(function(a,b){return b.eff_id-a.eff_id;}).map(item => {
      return (

        <tr key={item.eff_id}>
          <th scope="row">{item.eff_id}</th>
          <td>{item.author}</td>
          <td>{item.record_date}</td>
          <td>{item.tasks_done}</td>
          <td>{item.note}</td>
          <td>{item.pts}</td>
          <td className="text-right">

            <ModalForm buttonLabel="Edit" item={item} updateState={this.props.updateState}/>
          </td>
          <td className = "text-right">
           <Button
            className = "btn-round btn-icon"
            size="sm"
            color="danger"
            onClick={() => this.deleteItem(item.eff_id)}>
            <i className="tim-icons icon-simple-remove"/>
            </Button>


         </td>
       </tr>
      )
    })

    return (
    <div>
    <Card>

    {this.state.del_Alert &&
  <Alert
  toggle={this.onDismiss}
  color={this.state.del_suc?"success":"danger"}>
    <strong>{this.state.del_suc?"":"Errors: "}</strong> {this.state.del_msg}
  </Alert>}


    <CardBody>
    <div style={{
        maxHeight: '800px',
        overflowY: 'auto'
      }}>

        <Table  >

          <thead>
            <tr>
              <th>ID</th>
              <th>Author</th>
              <th>Record Date</th>
              <th>Tasks Done</th>
              <th>Note</th>
              <th>Points</th>

              <th className="text-right">Edit</th>
              <th className="text-right">Del</th>
            </tr>

          </thead>

          <tbody>
            {items}
          </tbody>

        </Table>
        </div>
        </CardBody>

    </Card>
    </div>
      )

  }


}

export default DataTable
