
import urlHead from "./backapi.js"

class AdminCheck{
  constructor() {
    this.admin = false;
    this.authenticated = false;
  }


  async isAuthenticated(){

    if (localStorage.authToken && localStorage.username)
    { //console.log("here??????????")
      var targetUrl = urlHead+"api/adminview/"
      await fetch(targetUrl,
      {
        method:'GET',
        headers:{
          'Content-Type':'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token '+localStorage.authToken
        }

      })
      .then(response=>response.json())
      .then(responseJSON=>{
        if ( "is_staff" in responseJSON[0] )
        { 
          if (responseJSON[0]["is_staff"] == true){
            this.admin = true
          }
          else{this.admin = false}
        }
      else{this.admin = false}
      })
    }

    //console.log("FINAL RETURNING SUOOOPID",this.authenticated)
    return this.admin;
  }


}

export default new AdminCheck()
