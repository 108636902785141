
import urlHead from "./backapi.js"

class UserAuth{
  constructor() {
    this.authenticated = false;
  }
  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb){
    this.authenticated = false;
    cb();
  }

  async isAuthenticated(){

    if (localStorage.authToken && localStorage.username)
    { //console.log("here??????????")
      var targetUrl = urlHead+"users/me/"
      await fetch(targetUrl,
      {
        method:'GET',
        headers:{
          'Content-Type':'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token '+localStorage.authToken
        }

      })
      .then(response=>response.json())
      .then(responseJSON=>{
        if ("id" in responseJSON && (responseJSON["username"]==localStorage.username))
        {
          this.authenticated = true
        //  console.log(responseJSON["username"],"successful verified")

          localStorage.setItem('loginStatus',"true")
        //  console.log("record store successfully")
          // for (let key in localStorage){
          //   console.log(key," in current local",localStorage[key])
          // }
          //console.log("TRUEEEE!!!",this.authenticated)
        }
        else if ("detail" in responseJSON){
          localStorage.removeItem('authToken')
          localStorage.setItem('loginStatus',"false")
        //  console.log("FALSE!!!")
          this.authenticated = false;
        }
        else{
          localStorage.removeItem('authToken')
          localStorage.setItem('loginStatus',"false")
          //console.log(localStorage.getItem('username'))
        //  console.log("FALSE!!!")
          this.authenticated  = false;
        }
      })
    }
    else
    {
      this.authenticated = false;
      localStorage.removeItem('authToken')
      localStorage.setItem('loginStatus',"false")
    }
    //console.log("FINAL RETURNING SUOOOPID",this.authenticated)
    return this.authenticated;
  }


}

export default new UserAuth()
