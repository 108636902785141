import React from 'react'
import { Table,
  Button,
  Card,
  CardHeader,
  CardBody,
  UncontrolledAlert,
  Alert,
  Container,
  Row,
  Col,
  CardImg,
  CardTitle,
  FormGroup,
  Label,
  Input,


 } from 'reactstrap';
 import {Redirect} from "react-router-dom"
import ProfileNavbar from "./profileBar.js"
import AdminCheck from "./adminVerify.js"
import urlHead from "./backapi.js"

class TaskTable extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      del_Alert : false,
      del_suc: false,
      del_msg:'',
      add_Alert : false,
      add_suc: false,
      add_msg:'',
      crits:[],
      adminFlag: true,
      att_field:'',
      pts_field:''
    }
this.handleFormChange=this.handleFormChange.bind(this)
  };
  handleFormChange(event){
    this.setState({
      [event.target.name]:event.target.value
    })
  }


  deleteItemfromState = (id)=>{
    const updatedItems = this.state.crits.filter(item=>item.cri_id != id)
    this.setState({crits:updatedItems})
  }

  async deleteCrit (id)
  {
    let confirmDelete = window.confirm('Delete item forever?')
    if (confirmDelete){
      await fetch (urlHead + "api/docrit/"+id.toString()+"/",
      {
        method: "DELETE",
        headers:{
          'Content-Type':'application/json',
          'Accept': '*/*',
          'Authorization':'Token '+localStorage.authToken
        }
      })
      .then(response=>{
        console.log(response.status) //******
        if (response.status == 204){
          this.setState({del_Alert:true})
          this.setState({del_suc:true})
          this.setState({del_msg:"successfully deleted the listed task"})
          this.deleteItemfromState(id)
        }
        else{
          this.setState({del_Alert:true})
          this.setState({del_suc:false})
          this.setState({del_msg:"unknown errors occured when deleting the listed task"})
        }
      })

      // .then(responseJSON=>{
      //   console.log(responseJSON.status=)
      // })
    }

  }



    onDismiss = () =>{this.setState({del_Alert:false})}

    onDm = () =>{this.setState({add_Alert:false})}



async getCritItems(){
  await fetch(urlHead+'api/docrit/',{
    method:'GET',
    headers:{
      'Content-Type':'application/json',
      'Accept': '*/*',
      'Authorization':'Token '+localStorage.authToken
    }
  })
  .then(async (response)=>{
        if (response.status==200){
          return response.json()
        }
        else{return []}
    })
  .then(async (responseJSON)=>{

    this.setState({crits:responseJSON})
    //this.state.crits = responseJSON
  //  console.log(responseJSON)
  })
  .catch(err=>console.log(err,"<===this is from fetching docrit"))
}

addItemtoState = (item) =>{
  this.setState(prevState=>({crits:[...prevState.crits,item]}))
}
updateState = (item)=>{
  const itemIdx = this.state.crits.findIndex(data => data.cri_id===item.cri_id)
  const newArr = [
    ...this.state.crits.slice(0,itemIdx),
    item,
    ...this.state.crits.slice(itemIdx+1)
  ]
  this.setState({crits:newArr})
}
async componentDidMount(){
  this.getCritItems()
  var stuff = await AdminCheck.isAuthenticated()
  this.setState({adminFlag:stuff})
}

 render(){
   var admin_status = false
   if (this.state.adminFlag == false ){
      admin_status = false
   }
   else{
     admin_status = true
   }
   const output_status = admin_status
   const add_notify = this.state.add_Alert
   const quote = this.state.del_Alert
    var tempArr = this.state.crits

    const crits_list = tempArr.map(
      item=>{return(
      <tr key={item.cri_id}>
        <th scope = "row">{item.cri_id}</th>
        <td>{item.cri_att}</td>
        <td>{item.pts}</td>
        <td className = "text-right">
          <Button
          className = "btn-round btn-icon"
          size = "sm"
          color = "danger"
          onClick= {async ()=> {

              await this.deleteCrit(item.cri_id)
          }}
          >
          <i className="tim-icons icon-simple-remove"/>
          </Button>
        </td>

      </tr>

    )}
  );

    return(
      <>
      {
        !admin_status &&   <Redirect push to = "/login" />
      }
      <ProfileNavbar />
      <div className = "content">
      <Container>
        <Row>
          <Col className = "offset-lg-0 offset-md-3" lg = "5" md="6">
            <Card className="card-stats">
            <CardHeader>

              <CardTitle tag="h2">Tasks Listed</CardTitle>
            </CardHeader>
          {quote&&  <Alert toggle = {this.onDismiss}
            color = {this.state.del_suc?"success":"danger"}
            >
            <strong>{this.state.del_suc?"":"ERROR: "}</strong> {this.state.del_msg}
            </Alert>}
            <CardBody>

            <div style = {{
              maxHeight:'500px',
              overflowY:'auto'
            }}>
            <Table >
              <thead>
                <tr>
                    <th>ID</th>
                    <th>Selected Tasks</th>
                    <th>Points Worth</th>
                    <th className="text-right"> Del</th>
                </tr>
              </thead>
              <tbody>
                {crits_list}
              </tbody>

            </Table>

            </div>

            </CardBody>
            </Card>

          </Col>

          <Col>
          <Container>
          <Row>
          <Col className = "offset-lg-5 offset-md-9" lg = "6" md="9">
          <Card className = "card-register">
          {add_notify &&  <Alert toggle = {this.onDm}
            color = {this.state.add_suc?"success":"danger"}
            >
            <strong>{this.state.add_suc?"":"ERROR: "}</strong> {this.state.add_msg}
            </Alert>}
          <CardBody>
          <form>
            <FormGroup>
              <Label for="taskName">Task</Label>
              <Input
                value = {this.state.att_field}
                name = "att_field"
                type = "text"
                id = "taskName"
                placeholder = "Enter Task Name"
                onChange = {this.handleFormChange}
              />
            </FormGroup>

            <FormGroup >
              <Label for="taskPts">Points</Label>
              <Input
                value = {this.state.pts_field}
                name = "pts_field"
                type = "number"
                id = "taskPts"
                placeholder = "Enter Points Worth"
                onChange = {this.handleFormChange}
              />
            </FormGroup>
            <Button
            color = "success"
            size = "sm"
            onClick={ async ()=>{
            await fetch(urlHead+'api/docrit/',{
              method:'POST',
              headers:{
                'Content-Type':'application/json',
                'Accept': '*/*',
                'Authorization':'Token '+localStorage.authToken
              },
              body:JSON.stringify({
                cri_att:this.state.att_field,
                pts:this.state.pts_field
              })
            })
            .then(response=>response.json())
            .then(responseJSON=>{
              if ("cri_id" in responseJSON){
                this.addItemtoState(responseJSON)
                this.setState({add_Alert:true})
                this.setState({add_suc:true})
                this.setState({add_msg:"successfully added the listed task"})
              }
              else{
                var output = ''
                for ( var temp in responseJSON) {
                  output = output+temp.toString()+": "+responseJSON[temp].toString()+"\n"
                }
                this.setState({add_Alert:true})
                this.setState({add_suc:false})
                this.setState({add_msg: output})
              }
            })

            }}>
            Add
            </Button>


          </form>

          </CardBody>
          </Card>
          </Col>
          </Row>
          </Container>
          </Col>

        </Row>


      </Container>


      </div>

</>



    )

  }



}


export default TaskTable
