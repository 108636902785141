import React from "react"
import ProtectedRoute from "./proute.js"
import LandingPage from "./Landing.js"
import Index from "./pack.js";
import RegisterPage from "./components/registerPage.js"
import ProfilePage from "./components/profile.js"
import LoginPage from "./components/loginPage.js"
import Lsuccess from "./loginSuccess.js";
import UserAuth from "./components/userAuth.js"
import DashBoard from "./components/dashboard.js"
import TaskTable from "./components/TaskBoard.js"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


// <ProtectedRoute
// exact path = "/app"
// component = {Index}
// />
function App() {

    return (
        <div className="page-header header-filter">
        <Switch>

        <Route exact path ="/" component = {Index} />
        <Route exact path ="/app" component = {Index} />

        <Route
            exact path="/register-page"
            component = {RegisterPage}

          />

      <Route
        exact path = "/tasks-select"
        component = {TaskTable}
        />

          <Route
              exact path="/profile"
              component = {ProfilePage}

            />
        <Route
            exact path="/welcome"
            component = {Lsuccess}

          />

          <Route
              exact path="/dashboard"
              component = {DashBoard}

            />



          <Route
              exact path = "/login"
              component = {LoginPage}
            />
          </Switch>
        </div>
    )
}

export default App
